interface NavAttributes {
  [propName: string]: any;
}
interface NavWrapper {
  attributes: NavAttributes;
  element: string;
}
interface NavBadge {
  text: string;
  variant: string;
}
interface NavLabel {
  class?: string;
  variant: string;
}

export interface NavData {
  name?: string;
  url?: string;
  icon?: string;
  badge?: NavBadge;
  title?: boolean;
  children?: NavData[];
  variant?: string;
  attributes?: NavAttributes;
  divider?: boolean;
  class?: string;
  label?: NavLabel;
  wrapper?: NavWrapper;
  permission?: string;
  deny?: string;
  displayMode?: string;
}

export const navItems: NavData[] = [
  // {
  //   name: "Dashboard",
  //   url: "/dashboard",
  //   icon: "icon-speedometer"
  // },
  {
    title: true,
    name: "SenseView",
  },
  {
    name: "Journey Times",
    icon: "fa fa-clock-o",
    url: "/senseview/journeytime",
    children: [
      {
        name: "Routes",
        url: "/senseview/journeytime/routes",
        icon: "icon-none",
      },
      {
        name: "Extended Routes",
        url: "/senseview/journeytime/extendedroutes",
        icon: "icon-none",
      },
      {
        name: "Journeys",
        url: "/senseview/journeytime/journeydata",
        icon: "icon-none",
      },
      {
        name: "Extended Journeys",
        url: "/senseview/journeytime/extendedjourneydata",
        icon: "icon-none",
      },
      {
        name: "Counts",
        url: "/senseview/journeytime/counts",
        icon: "icon-none",
        //permission: "WVC",
      },
      {
        name: "Device Tracking",
        url: "/senseview/journeytime/devices",
        icon: "icon-none",
      },
      {
        name: "Detections",
        url: "/senseview/journeytime/data",
        icon: "icon-none",
      },
      {
        name: "Raw Detections",
        url: "/senseview/journeytime/data-raw",
        icon: "icon-none",
      },
      {
        name: "Hourly Detections",
        url: "/senseview/journeytime/data-hourly",
        icon: "icon-none",
      },
      {
        name: "Historical Comparisons",
        url: "/senseview/journeytime/routes/compare",
        icon: "icon-none",
        //permission: "GLA",
      },
    ],
  },
  {
    name: "Pollution",
    icon: "fa fa-cloud",
    url: "/senseview/pollution",
    children: [
      {
        name: "Sites",
        url: "/senseview/pollution/sites",
        icon: "icon-none",
      },
      {
        name: "Data",
        url: "/senseview/pollution/data",
        icon: "icon-none",
      },
      {
        name: "NO2 Sites",
        url: "/senseview/pollution/sites-no2",
        icon: "icon-none",
      },
      {
        name: "NO2 Data",
        url: "/senseview/pollution/data-no2",
        icon: "icon-none",
      },
      {
        name: "NO2 (AUX) Sites",
        url: "/senseview/pollution/sites-no2-aux",
        icon: "icon-none",
      },
      {
        name: "NO2 (AUX) Data",
        url: "/senseview/pollution/data-no2-aux",
        icon: "icon-none",
      },

      {
        name: "NO Sites",
        url: "/senseview/pollution/sites-no",
        icon: "icon-none",
      },
      {
        name: "NO Data",
        url: "/senseview/pollution/data-no",
        icon: "icon-none",
      },
      {
        name: "CO2 Sites",
        url: "/senseview/pollution/sites-co2",
        icon: "icon-none",
      },
      {
        name: "CO2 Data",
        url: "/senseview/pollution/data-co2",
        icon: "icon-none",
      },
    ],
  },
  {
    name: "Particulates",
    icon: "fa fa-dot-circle-o",
    url: "/senseview/particulate",
    children: [
      {
        name: "Sites",
        url: "/senseview/particulate/sites",
        icon: "icon-none",
      },
      {
        name: "Data",
        url: "/senseview/particulate/data",
        icon: "icon-none",
      },
    ],
  },
  {
    name: "Video",
    icon: "fa fa-video-camera",
    url: "/senseview/video",
    children: [
      { name: "Zones", url: "/senseview/video/zones", icon: "icon-none" },
      {
        name: "ANPR Nodes",
        url: "/senseview/video/anpr-nodes",
        icon: "icon-none",
      },
      { name: "Detections", url: "/senseview/video/data", icon: "icon-none" },
      {
        name: "Live Detections",
        url: "/senseview/video/live-detections",
        icon: "icon-none",
      },
      {
        name: "Traces",
        url: "/senseview/video/path-tracing/zones",
        icon: "icon-none",
      },
    ],
  },
  {
    name: "CCTV",
    icon: "fa fa-tv",
    url: "/senseview/cctv",
    //permission: "STA,WKF,SOU,KRK",
  },
  {
    name: "Turn Analysis",
    icon: "fa fa-arrows",
    url: "/senseview/turn-analysis",
    badge: {
      variant: "danger",
      text: "Beta",
    },
    //permission: "STA,SOU",
  },
  {
    name: "Virtual Routes",
    icon: "fa fa-folder-open",
    url: "/senseview/virtual/routes",
    //children: [
    //  { name: "Routes", url: "/senseview/virtual/routes", icon: "icon-none" },
    //],
  },
  {
    name: "Environment Data",
    icon: "fa fa-folder-open",
    url: "/senseview/environment/sites",
    badge: {
      variant: "danger",
      text: "Beta",
    },
  },
  {
    name: "Weather Data",
    icon: "fa fa-folder-open",
    url: "/senseview/weather",
    badge: {
      variant: "danger",
      text: "Beta",
    },
    children: [
      { name: "Sites", url: "/senseview/weather/sites", icon: "icon-none" },
    ],
  },
  {
    name: "Car Parking",
    icon: "fa fa-car",
    url: "/senseview/car-parking",
    badge: {
      variant: "danger",
      text: "Beta",
    },
  },
  {
    name: "Alerts",
    icon: "fa fa-bell-o",
    url: "/senseview/alerts",
    badge: {
      variant: "danger",
      text: "Beta",
    },
    children: [
      {
        name: "Video Analysis",
        url: "/senseview/alerts/video-analysis",
        icon: "icon-none",
      },
      {
        name: "Monitoring System",
        url: "/senseview/alerts/monitoring",
        icon: "icon-none",
      },
    ],
  },
  {
    name: "Brainboxes",
    icon: "fa fa-sliders",
    url: "/senseview/brainboxes",
    badge: {
      variant: "danger",
      text: "Beta",
    },
  },
  {
    name: "Nodes",
    icon: "fa fa-cogs",
    url: "/senseview/nodes",
    children: [
      {
        name: "Overview",
        url: "/senseview/nodes/overview",
        icon: "icon-none",
      },
      {
        name: "Groups",
        url: "/senseview/nodes/groups",
        icon: "icon-none",
      },
    ],
  },
  {
    name: "Login Request",
    icon: "fa fa-users",
    url: "/senseview/request-login",
  },
  /*{
    name: "Mesh Systems",
    children: [
      {
        name: "Network Map",
        icon: "fa fa-map-o",
        url: "https://maps.mesh4g.com/map.html",
        attributes: { target: "_blank" },
      },
      {
        name: "Availability",
        icon: "fa fa-bookmark-o",
        url: "https://maps.mesh4g.com/live-availability.html",
        attributes: { target: "_blank" },
      },
    ],
  },*/
];
